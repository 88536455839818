import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-6 mb-5"
}
const _hoisted_3 = { class: "card mb-5 mb-xl-10" }
const _hoisted_4 = {
  "aria-controls": "kt_account_connected_accounts",
  "aria-expanded": "true",
  class: "card-header border-0 cursor-pointer",
  "data-bs-target": "#kt_account_connected_accounts",
  "data-bs-toggle": "collapse",
  role: "button"
}
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = { class: "fw-bolder m-0" }
const _hoisted_7 = {
  id: "kt_account_connected_accounts",
  class: "collapse show"
}
const _hoisted_8 = { class: "card-body border-top p-9" }
const _hoisted_9 = { class: "py-2" }
const _hoisted_10 = { class: "d-flex flex-stack" }
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = { class: "d-flex flex-column" }
const _hoisted_13 = {
  class: "fs-5 text-dark text-hover-primary fw-bolder",
  href: "#"
}
const _hoisted_14 = { class: "d-flex justify-content-end" }
const _hoisted_15 = { class: "form-check form-check-solid form-switch" }
const _hoisted_16 = ["onChange"]
const _hoisted_17 = ["onChange"]
const _hoisted_18 = { class: "d-flex flex-stack" }
const _hoisted_19 = { class: "d-flex" }
const _hoisted_20 = { class: "d-flex flex-column" }
const _hoisted_21 = {
  class: "fs-5 text-dark text-hover-primary fw-bolder",
  href: "#"
}
const _hoisted_22 = { class: "d-flex justify-content-end" }
const _hoisted_23 = { class: "form-check form-check-solid form-switch" }
const _hoisted_24 = ["onChange"]
const _hoisted_25 = ["onChange"]
const _hoisted_26 = { class: "d-flex flex-stack" }
const _hoisted_27 = { class: "d-flex" }
const _hoisted_28 = { class: "d-flex flex-column" }
const _hoisted_29 = {
  class: "fs-5 text-dark text-hover-primary fw-bolder",
  href: "#"
}
const _hoisted_30 = { class: "d-flex justify-content-end" }
const _hoisted_31 = { class: "form-check form-check-solid form-switch" }
const _hoisted_32 = ["onChange"]
const _hoisted_33 = ["onChange"]
const _hoisted_34 = { class: "d-flex flex-stack" }
const _hoisted_35 = { class: "d-flex" }
const _hoisted_36 = { class: "d-flex flex-column" }
const _hoisted_37 = {
  class: "fs-5 text-dark text-hover-primary fw-bolder",
  href: "#"
}
const _hoisted_38 = { class: "d-flex justify-content-end" }
const _hoisted_39 = { class: "form-check form-check-solid form-switch" }
const _hoisted_40 = ["onChange"]
const _hoisted_41 = ["onChange"]
const _hoisted_42 = { class: "d-flex flex-stack" }
const _hoisted_43 = { class: "d-flex" }
const _hoisted_44 = { class: "d-flex flex-column" }
const _hoisted_45 = {
  class: "fs-5 text-dark text-hover-primary fw-bolder",
  href: "#"
}
const _hoisted_46 = { class: "d-flex justify-content-end" }
const _hoisted_47 = { class: "form-check form-check-solid form-switch" }
const _hoisted_48 = ["onChange"]
const _hoisted_49 = ["onChange"]
const _hoisted_50 = { class: "d-flex flex-stack" }
const _hoisted_51 = { class: "d-flex" }
const _hoisted_52 = { class: "d-flex flex-column" }
const _hoisted_53 = {
  class: "fs-5 text-dark text-hover-primary fw-bolder",
  href: "#"
}
const _hoisted_54 = { class: "d-flex justify-content-end" }
const _hoisted_55 = { class: "form-check form-check-solid form-switch" }
const _hoisted_56 = ["onChange"]
const _hoisted_57 = ["onChange"]
const _hoisted_58 = { class: "d-flex flex-stack" }
const _hoisted_59 = { class: "d-flex" }
const _hoisted_60 = { class: "d-flex flex-column" }
const _hoisted_61 = {
  class: "fs-5 text-dark text-hover-primary fw-bolder",
  href: "#"
}
const _hoisted_62 = { class: "d-flex justify-content-end" }
const _hoisted_63 = { class: "form-check form-check-solid form-switch" }
const _hoisted_64 = ["onChange"]
const _hoisted_65 = ["onChange"]
const _hoisted_66 = { class: "d-flex flex-stack" }
const _hoisted_67 = { class: "d-flex" }
const _hoisted_68 = { class: "d-flex flex-column" }
const _hoisted_69 = {
  class: "fs-5 text-dark text-hover-primary fw-bolder",
  href: "#"
}
const _hoisted_70 = { class: "d-flex justify-content-end" }
const _hoisted_71 = { class: "form-check form-check-solid form-switch" }
const _hoisted_72 = ["onChange"]
const _hoisted_73 = ["onChange"]
const _hoisted_74 = { class: "d-flex flex-stack" }
const _hoisted_75 = { class: "d-flex justify-content-end" }
const _hoisted_76 = { class: "form-check form-check-solid form-switch" }
const _hoisted_77 = ["onChange"]
const _hoisted_78 = ["onChange"]
const _hoisted_79 = { class: "d-flex flex-stack" }
const _hoisted_80 = { class: "d-flex justify-content-end" }
const _hoisted_81 = { class: "form-check form-check-solid form-switch" }
const _hoisted_82 = ["onChange"]
const _hoisted_83 = ["onChange"]
const _hoisted_84 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_85 = { class: "btn btn-white btn-active-light-primary me-2" }
const _hoisted_86 = ["onClick"]
const _hoisted_87 = { class: "indicator-label" }
const _hoisted_88 = { class: "indicator-progress" }
const _hoisted_89 = { class: "indicator-validate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.userTypes, (usertype) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: usertype.id
      }, [
        (usertype.position !== 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(usertype.label), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-folder-open fs-1 w-30px me-6" }, null, -1)),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t("pages.dashboard.drive")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            (usertype.services.indexOf('drive') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('drive', usertype.id))
                                }, null, 40, _hoisted_16))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('drive', usertype.id))
                                }, null, 40, _hoisted_17)),
                            _cache[1] || (_cache[1] = _createElementVNode("label", {
                              class: "form-check-label",
                              for: ""
                            }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-chart-line fs-1 w-30px me-6" }, null, -1)),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("a", _hoisted_21, _toDisplayString(_ctx.$t("pages.dashboard.attendanceMonitoring")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            (usertype.services.indexOf('attendance') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('attendance', usertype.id))
                                }, null, 40, _hoisted_24))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('attendance', usertype.id))
                                }, null, 40, _hoisted_25)),
                            _cache[3] || (_cache[3] = _createElementVNode("label", {
                              class: "form-check-label",
                              for: "googleswitch"
                            }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[21] || (_cache[21] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-poll fs-1 w-30px me-6" }, null, -1)),
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("a", _hoisted_29, _toDisplayString(_ctx.$t("pages.dashboard.results")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", _hoisted_31, [
                            (usertype.services.indexOf('results') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('results', usertype.id))
                                }, null, 40, _hoisted_32))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('results', usertype.id))
                                }, null, 40, _hoisted_33)),
                            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-check-label" }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("div", _hoisted_35, [
                          _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fas fa-map-marked-alt fs-1 w-30px me-6" }, null, -1)),
                          _createElementVNode("div", _hoisted_36, [
                            _createElementVNode("a", _hoisted_37, _toDisplayString(_ctx.$t("pages.roadmap.title")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_38, [
                          _createElementVNode("div", _hoisted_39, [
                            (usertype.services.indexOf('roadmap') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('roadmap', usertype.id))
                                }, null, 40, _hoisted_40))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('roadmap', usertype.id))
                                }, null, 40, _hoisted_41)),
                            _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-check-label" }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[23] || (_cache[23] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_42, [
                        _createElementVNode("div", _hoisted_43, [
                          _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fas fa-lightbulb fs-1 w-30px me-6" }, null, -1)),
                          _createElementVNode("div", _hoisted_44, [
                            _createElementVNode("a", _hoisted_45, _toDisplayString(_ctx.$t("pages.ideas.title")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_46, [
                          _createElementVNode("div", _hoisted_47, [
                            (usertype.services.indexOf('ideas_box') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('ideas_box', usertype.id))
                                }, null, 40, _hoisted_48))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('ideas_box', usertype.id))
                                }, null, 40, _hoisted_49)),
                            _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-check-label" }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[24] || (_cache[24] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_50, [
                        _createElementVNode("div", _hoisted_51, [
                          _cache[10] || (_cache[10] = _createElementVNode("i", { class: "fas fa-lightbulb fs-1 w-30px me-6" }, null, -1)),
                          _createElementVNode("div", _hoisted_52, [
                            _createElementVNode("a", _hoisted_53, _toDisplayString(_ctx.$t("pages.usefulTraining.title")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_54, [
                          _createElementVNode("div", _hoisted_55, [
                            (usertype.services.indexOf('useful-training') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('useful-training', usertype.id))
                                }, null, 40, _hoisted_56))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('useful-training', usertype.id))
                                }, null, 40, _hoisted_57)),
                            _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-check-label" }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[25] || (_cache[25] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_58, [
                        _createElementVNode("div", _hoisted_59, [
                          _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fas fa-users fs-1 w-30px me-6" }, null, -1)),
                          _createElementVNode("div", _hoisted_60, [
                            _createElementVNode("a", _hoisted_61, _toDisplayString(_ctx.$t("pages.dashboard.users")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_62, [
                          _createElementVNode("div", _hoisted_63, [
                            (usertype.services.indexOf('users') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('users', usertype.id))
                                }, null, 40, _hoisted_64))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('users', usertype.id))
                                }, null, 40, _hoisted_65)),
                            _cache[13] || (_cache[13] = _createElementVNode("label", { class: "form-check-label" }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[26] || (_cache[26] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_66, [
                        _createElementVNode("div", _hoisted_67, [
                          _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fas fa-sitemap fs-1 w-30px me-6" }, null, -1)),
                          _createElementVNode("div", _hoisted_68, [
                            _createElementVNode("a", _hoisted_69, _toDisplayString(_ctx.$t("pages.dashboard.entities")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_70, [
                          _createElementVNode("div", _hoisted_71, [
                            (usertype.services.indexOf('entities') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('entities', usertype.id))
                                }, null, 40, _hoisted_72))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('entities', usertype.id))
                                }, null, 40, _hoisted_73)),
                            _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-check-label" }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[27] || (_cache[27] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_74, [
                        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "d-flex" }, [
                          _createElementVNode("i", { class: "fas fa-eye fs-1 w-30px me-6" }),
                          _createElementVNode("div", { class: "d-flex flex-column" }, [
                            _createElementVNode("a", {
                              class: "fs-5 text-dark text-hover-primary fw-bolder",
                              href: "#"
                            }, "Enquête")
                          ])
                        ], -1)),
                        _createElementVNode("div", _hoisted_75, [
                          _createElementVNode("div", _hoisted_76, [
                            (usertype.services.indexOf('survey') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('survey', usertype.id))
                                }, null, 40, _hoisted_77))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('survey', usertype.id))
                                }, null, 40, _hoisted_78)),
                            _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-check-label" }, null, -1))
                          ])
                        ])
                      ]),
                      _cache[28] || (_cache[28] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_79, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "d-flex" }, [
                          _createElementVNode("i", { class: "fas fa-chart-pie fs-1 w-30px me-6" }),
                          _createElementVNode("div", { class: "d-flex flex-column" }, [
                            _createElementVNode("a", {
                              class: "fs-5 text-dark text-hover-primary fw-bolder",
                              href: "#"
                            }, "Statistiques")
                          ])
                        ], -1)),
                        _createElementVNode("div", _hoisted_80, [
                          _createElementVNode("div", _hoisted_81, [
                            (usertype.services.indexOf('statistics') !== -1)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  checked: "",
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('statistics', usertype.id))
                                }, null, 40, _hoisted_82))
                              : (_openBlock(), _createElementBlock("input", {
                                  key: 1,
                                  class: "form-check-input w-40px h-20px",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.switchServices('statistics', usertype.id))
                                }, null, 40, _hoisted_83)),
                            _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-check-label" }, null, -1))
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_84, [
                    _createElementVNode("button", _hoisted_85, _toDisplayString(_ctx.$t("general.cancel")), 1),
                    _createElementVNode("button", {
                      ref_for: true,
                      ref: 'submitButton' + usertype.position,
                      class: "btn btn-primary",
                      onClick: ($event: any) => (_ctx.updateUserType(usertype))
                    }, [
                      _createElementVNode("span", _hoisted_87, _toDisplayString(_ctx.$t("general.save")), 1),
                      _createElementVNode("span", _hoisted_88, [
                        _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                        _cache[29] || (_cache[29] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
                      ]),
                      _createElementVNode("span", _hoisted_89, [
                        _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                        _cache[30] || (_cache[30] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
                      ])
                    ], 8, _hoisted_86)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}